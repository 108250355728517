/** @jsx jsx */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */

import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import ArrowDown from '../../images/clickables/arrow_down.svg';
import SwitchLogo from '../../images/switch-logo.svg';

import {CTA} from './Clickables';
import ClientOnly from './ClientOnly';
import {P2, Link} from './Typography';
import {breakpoints, colors, fonts, fontWeights, maxWidth} from '../../styles/theme';

// About
import CareersLogo from '../../images/header/about/careers.png';
import CompanyLogo from '../../images/header/about/company.png';
import ContactUsLogo from '../../images/header/about/contact-us.png';
import NewsroomLogo from '../../images/header/about/newsroom.png';

// Developers
// TODO: when we have the API status page, we can use the developer's submenu
// import APIStatusLogo from '../../images/header/developers/api-status.png';
// import DocumentationLogo from '../../images/header/developers/documentation.png';

// Product
import AnalyticsLogo from '../../images/header/product/analytics.png';
import DynamicRoutingLogo from '../../images/header/product/dynamic-routing.png';
import OverviewLogo from '../../images/header/product/overview.png';
import ProcessingLogo from '../../images/header/product/processing.png';
import ReconciliationLogo from '../../images/header/product/reconciliation.png';
import RiskLogo from '../../images/header/product/risk.png';
import TerminalLogo from '../../images/header/product/terminal.png';
import VaultLogo from '../../images/header/product/vault.png';

// Use Cases
import AcquirersLogo from '../../images/header/use-cases/acquirers.png';
import ISVsLogo from '../../images/header/use-cases/isvs.png';
import MerchantsLogo from '../../images/header/use-cases/merchants.png';
import PSPsLogo from '../../images/header/use-cases/psps.png';


/*
 * Constants
 */
/* eslint-disable-next-line i18next/no-literal-string */
const activeNavClassName = 'active-nav-link';
/* eslint-disable-next-line i18next/no-literal-string */
const activeSubNavClassName = 'active-sub-nav-link';
const navbarDropdownAnimationTime = 500;  // milliseconds


// Fix for bug of overlapping media queries between md and lg (happens on iPad/tablet screen size).
// This value is breakpoints.md minus 0,02px.
// More info on why here: https://stackoverflow.com/q/51566916/7564579
const PRE_MD = '767.98px';


/*
 * Private Elements
 */

// Switch logo in black
const opaqueBackground = css`
    background-color: ${colors.white};
`;

// Switch logo in black
const darkLogo = css`
    .navbar-brand {
        svg {
            * {
                fill: black;
            }
        }
    }
`;

// Theme changes for contrast (light) pages
const contrastTheme = css`
    ${darkLogo};

    .navbar-collapse {
        .navbar-nav {
            .nav-link {
                color: ${colors.black};
            }
        }
    }
`;

// Navbar
const StyledNavbar = styled.nav`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 12px 0 12px;
    height: 64px;
    z-index: 1001;
    font-family: ${fonts.sansSerif};

    .${activeNavClassName} {
        font-weight: ${fontWeights.bold} !important;
        color: ${colors.darkPurple} !important;
    }

    .${activeSubNavClassName} {
        color: ${colors.darkPurple} !important;
    }

    .navbar-content-delimiter {
        max-width: ${maxWidth}px;
        width: 100%;
        margin: auto;

        .mobile-toggle-content-delimiter {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }

    // Make sure we are using a dark logo when the menu is expanded
    &[data-expanded='true'] {
        z-index: 1050;
        position: fixed;
        ${darkLogo};
        ${opaqueBackground};
    }

    .navbar-content-delimiter {
        max-width: ${maxWidth}px;
        width: 100%;
        margin: auto;

        .mobile-toggle-content-delimiter {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }

    .navbar-brand {
        padding-left: 23px;
    }

    .navbar-toggler {
        padding: 5px 23px;
    }

    .navbar-collapse {
        margin-top: 11px;
        padding: 0 23px 0 23px;
        background-color: ${colors.white};

        .navbar-nav {
            padding-top: 19px;

            .nav-link {
                font-size: 20px;
                font-weight: ${fontWeights.bold};
                color: ${colors.black};
                padding: 10px 0;

                svg {
                    * {
                        stroke: ${colors.black};
                    }

                    &.inverted {
                        transform: scaleY(-1);
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    color: ${colors.darkPurple};

                    svg {
                        * {
                            stroke: ${colors.darkPurple};
                        }
                    }
                }

                &.active-link {
                    font-weight: ${fontWeights.bold};
                }
            }

            .dropdown-toggle {
                &::after {
                    content: none;
                }
            }
        }
    }

    #navbarNavDropdown {
        width: 100%;
        height: 100%;
        max-height: 0;
        transition: max-height ${navbarDropdownAnimationTime / 1000}s ease-in;

        .navbar-dropdown-content-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
        }

        &.open {
            // Due to how the mobile display works when scrolling (the address bar moving in and out of the screen,
            // bottom placed elements will not display in the correct place in all situations. In order to get around
            // this, we place the element with 99% max-height.
            // Read more here: https://developers.google.com/web/updates/2016/12/url-bar-resizing
            max-height: 99%;
        }
    }

    @media (max-width: ${PRE_MD}) {
        #navbarNavDropdown {
            position: fixed;
            overflow-y: scroll;
        }

        .navbar-collapse {
            .navbar-nav {
                &.content-items {
                    width: 100%;
                }

                &.sign-in {
                    position: absolute;
                    bottom: 75px;
                    right: 23px;
                }
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        padding: 0;
        height: 112px;

        .navbar-content-delimiter {
            display: flex;
            justify-content: space-between;

            .mobile-toggle-content-delimiter {
                width: auto;
            }
        }

        .navbar-brand {
            padding-left: 65px;
        }

        .navbar-collapse {
            padding-right: 65px;
            margin-top: 0;
            background-color: transparent;

            .navbar-nav {
                padding: 0;

                &.content-items {
                    width: 100%;
                    justify-content: center;
                }

                .nav-link {
                    color: ${colors.white};
                    font-weight: ${fontWeights.regular};
                    font-size: inherit;
                    line-height: inherit;
                    padding: 30px 5px;
                }
            }
        }

        #navbarNavDropdown {
            max-height: inherit;
            height: inherit !important;
            display: none;
        }
    }

    @media (min-width: 900px) {
        .navbar-collapse {
            .navbar-nav {
                &.content-items {
                    margin-left: auto;
                    margin-right: auto;
                }

                .nav-link {
                    padding: 30px 10px;
                }
            }
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        .navbar-collapse {
            .navbar-nav {
                .nav-link {
                    padding: 30px 20px;
                }
            }
        }
    }

    @media (min-width: 1050px) {
        .navbar-collapse {
            .navbar-nav {
                .nav-link {
                    padding: 30px 25px;
                }
            }
        }
    }
`;

// Sign in button
const ClickableCircle = styled.div`
    // Note: we are using #000000 for black instead of the theme "black" for the gradient, so that we have a more
    // pronounced gradient.

    color: ${colors.white};
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    margin-top: auto;
    margin-left: auto;
    background: linear-gradient(to top right, ${colors.darkPurple}, #000000);
    z-index: 1;

    p {
        line-height: 80px;
        font-weight: ${fontWeights.regular};
    }

    &::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        background: linear-gradient(to top right, #000000, ${colors.darkPurple});
        z-index: -1;
        transition: opacity 0.5s linear;
        opacity: 0;
    }

    &:hover {
        color: ${colors.white};

        &::before {
            opacity: 1;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        margin-left: inherit;
    }
`;

/**
 * Sign In button
 */
const SignIn = () => (
    <a className="nav-link" href="https://dashboard.switchpayments.com/" target="_blank" style={{padding: 0}}>
        <ClickableCircle><P2><Trans>Sign In</Trans></P2></ClickableCircle>
    </a>
);

// Menu toggle
const StyledToggle = styled.button`
    &:focus {
        outline: none;
    }

    #hamburger {
        width: 30px;
        height: 21px;
        position: relative;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: linear-gradient(to right, ${colors.black}, ${colors.darkPurple});
            opacity: 1;
            left: 0;
            transition: .25s ease-in-out;
            transform-origin: left center;

            &:nth-of-type(1) {
                top: 3px;
            }

            &:nth-of-type(2) {
                top: 10px;
            }

            &:nth-of-type(3) {
                top: 17px;
            }
        }

        &.open {
            span {
                width: 29px;

                &:nth-of-type(1) {
                    transform: rotate(45deg);
                    top: -1px;
                    left: 3px;
                }

                &:nth-of-type(2) {
                    width: 0;
                    opacity: 0;
                }

                &:nth-of-type(3) {
                    transform: rotate(-45deg);
                    top: 20px;
                    left: 3px;
                }
            }
        }
    }
`;

/**
 * Display the hamburger toggle for the mobile menu
 *
 * @param {boolean} opened - whether the menu is opened or not
 * @param {function} setNavbarDropdownVisibility - method to update whether it is opened
 */
const HamburgerToggle = ({opened, setNavbarDropdownVisibility}) => {
    const {t} = useTranslation();

    /* eslint-disable-next-line i18next/no-literal-string */
    const className = opened ? 'open' : '';

    /**
     * Toggle the visibility class on the navbar
     */
    function toggleClasses() {
        setNavbarDropdownVisibility(!opened);
    }

    return (
        <StyledToggle
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label={t('Toggle navigation')}
            onClick={toggleClasses}
        >
            <div id="hamburger" className={className}>
                <span />
                <span />
                <span />
            </div>
        </StyledToggle>
    );
};

HamburgerToggle.propTypes = {
    opened: PropTypes.bool.isRequired,
    setNavbarDropdownVisibility: PropTypes.func.isRequired,
};

// Header links
/**
 * Main menu link (without dropdown)
 *
 * @param {string} to - the link to redirect to
 * @param {function} setNavbarDropdownVisibility - method to set the main menu dropdown visibility
 * @param {node} children - content of the page
 */
const HeaderLink = ({to, setNavbarDropdownVisibility, children}) => (
    <li className="nav-item">
        <Link
            className="nav-link"
            to={to}
            activeClassName={activeNavClassName}
            onClick={() => setNavbarDropdownVisibility(false)}
        >
            {children}
        </Link>
    </li>
);

HeaderLink.propTypes = {
    to: PropTypes.string.isRequired,
    setNavbarDropdownVisibility: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};


const StyledHeaderDropdownLink = styled.li`
    & > ${Link} {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    &.dropdown {
        position: unset;
    }

    .dropdown-menu {
        margin: 0;
        padding-top: 0;
        border: 0;

        ${Link} {
            padding: 0;

            &:hover,
            &:focus {
                color: ${colors.darkPurple};
                background-color: ${colors.transparent};
            }

            & > svg {
                width: 30px;
                margin-right: 12px;
            }

            .link-title {
                font-size: 12px;
                line-height: 15px;
                font-weight: ${fontWeights.bold};
                letter-spacing: 3px;
                text-transform: uppercase;
            }

            .link-description {
                display: none;
                color: ${colors.darkGrey};
            }
        }

        .dropdown-item {
            &.last {
                margin-bottom: 50px;
            }

            .icon {
                width: 30px;
                margin-right: 15px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                vertical-align: middle;
            }
        }

        .overview {
            display: none;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .dropdown-toggle {
            position: relative;
            cursor: default;

            .dropdown-item-caret {
                display: none;
            }
        }

        .dropdown-menu {
            background-color: transparent;
            padding: 0 25px;
            width: fit-content;
            max-width: min(${maxWidth}px, 100vw);

            &.show {
                position: absolute;
                top: 98px;
                left: 50%;
                transform: translate(-50%, 0);

                .dropdown-menu-item-wrapper {
                    .dropdown-menu-item-container {
                        display: flex;
                        flex-direction: row;
                        background-color: ${colors.white};
                        border-radius: 5px;
                        box-shadow: 0 10px 17px 0 rgba(35, 35, 38, 0.3);

                        .dropdown-item {
                            width: fit-content;

                            &.last {
                                margin-bottom: 0;
                            }

                            .icon {
                                width: 45px;
                                margin: 0;
                                padding: 0;
                            }
                        }

                        .dropdown-column {
                            padding: 3vw 4vw;
                            display: flex;
                            flex-direction: column;
                            border-radius: 5px;
                            flex-basis: 0;
                            flex-grow: 3.5;

                            &.main {
                                padding-left: 2vw;
                                padding-right: 2vw;
                                flex-grow: 1;
                                background-color: #F7F7FF;

                                ${Link} {
                                    // TODO: remove this when there is a new overview page 
                                    &.overview {
                                        display: block;
                                        cursor: default;

                                        * {
                                            cursor: default;
                                        }

                                        .link-title {
                                            color: ${colors.black} !important;
                                        }

                                        .cta-arrow {
                                            display: none;
                                        }
                                    }

                                    &.dropdown-item {
                                        padding: 0;
                                    }

                                    .link-description {
                                        padding-top: 15px;
                                    }
                                }
                            }

                            &.secondary {
                                padding-left: 1vw;

                                ${Link} {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }

                            .dropdown-row {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                              
                                &:nth-of-type(2) {
                                    margin-top: 30px;
                                }

                                & > * {
                                    flex-basis: 0;
                                    flex-grow: 1;
                                }

                                .filler {
                                    visibility: hidden;
                                }
                            }

                            ${Link} {
                                padding-left: 3vw;
                                padding-right: 3vw;
                            }
                        }

                        ${Link} {
                            padding-left: 25px;
                            padding-right: 25px;
                            flex-direction: column;
                            white-space: pre-wrap;

                            & > * {
                                display: block;
                            }
    
                            svg {
                                width: 45px;
                                height: 45px;
                            }
    
                            .link-title {
                                padding-top: 20px;
                            }
    
                            .link-description {
                                padding-top: 5px;

                                a, span {
                                    font-size: 24px;
                                    line-height: 32px;
                                    text-transform: none;
                                    font-weight: ${fontWeights.semiLight};
                                    letter-spacing: 0;

                                    span {
                                        display: block;
                                        margin-top: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        .dropdown-menu {
            padding: 0 65px;
        }
    }
`;

const DropdownOpenMenuIndicator = styled.span`
    display: none;
    position: absolute;
    top: 85px;
    right: calc(50% - 8px);
    z-index: 1001;
    background-color: ${colors.darkPurple};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: ${breakpoints.md}) {
        &.show {
            display: block;
        }
    }
`;

/**
 * Main menu link (with dropdown)
 *
 * @param {string} id - the element's ID
 * @param {string} name - the link's text
 * @param {string} partialPath - the partial path to match with to show the link as active
 * @param {boolean} opened - whether the dropdown should be displayed or not
 * @param {function} setOpened - method to set the dropdown visibility
 * @param {function} setNavbarDropdownVisibility - method to set the main menu dropdown visibility
 * @param {node} children - content of the page
 */
const HeaderDropdownLink = ({id, name, partialPath, opened, setOpened, setNavbarDropdownVisibility, children}) => {
    const [currentPath, setCurrentPath] = useState('/');
    const mdBreakpoint = parseInt(breakpoints.md.replace('px', ''), 10);

    /* eslint-disable-next-line i18next/no-literal-string */
    const dropDownElementsClass = opened ? 'show' : '';
    /* eslint-disable-next-line i18next/no-literal-string */
    const dropDownCaretClass = opened ? 'inverted' : '';

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useEffect(() => setCurrentPath(window.location.pathname));

    /**
     * Close the actual navbar dropdown (when the user clicks on a navigation element)
     */
    const closeMenu = () => {
        setOpened(false);
        setNavbarDropdownVisibility(false);
    };

    /**
     * Change the current state of the navbar dropdown in the desktop
     */
    const changeDesktopDropdownState = newState => () => {
        if (window.innerWidth >= mdBreakpoint) {
            setOpened(newState);
        }
    };

    /**
     * Change the current state of the navbar dropdown in mobile
     */
    const changeMobileDropdownState = newState => () => {
        if (window.innerWidth < mdBreakpoint) {
            setOpened(newState);
        }
    };

    return (
        <StyledHeaderDropdownLink
            className="nav-item dropdown"
            onClick={changeMobileDropdownState(!opened)}
            onMouseEnter={changeDesktopDropdownState(true)}
            onMouseLeave={changeDesktopDropdownState(false)}
        >
            <Link
                className="nav-link dropdown-toggle"
                id={id}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                role="menuitem"
                forceActive={currentPath.startsWith(partialPath)}
                activeClassName={activeNavClassName}
            >
                {name}
                <ArrowDown className={`dropdown-item-caret ${dropDownCaretClass}`} />
                <ClientOnly><DropdownOpenMenuIndicator className={dropDownElementsClass} /></ClientOnly>
            </Link>
            <div className={`dropdown-menu ${dropDownElementsClass}`} aria-labelledby={id}>
                <div className="dropdown-menu-item-wrapper">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <div className="dropdown-menu-item-container" onClick={closeMenu}>
                        {children}
                    </div>
                </div>
            </div>
        </StyledHeaderDropdownLink>
    );
};

HeaderDropdownLink.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    partialPath: PropTypes.string.isRequired,
    opened: PropTypes.bool.isRequired,
    setOpened: PropTypes.func.isRequired,
    setNavbarDropdownVisibility: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};


/*
 * Public Elements
 */
/**
 * Display the page's header
 *
 * @param {boolean} contrast - whether the page is uses a contrast (light) theme
 */
const Header = ({contrast = false}) => {
    const {t} = useTranslation();
    const [dataExpanded, setDataExpanded] = useState(false);
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

    // Only one dropdown can be opened at a time, and "null" means no dropdown is opened
    const [openedHeaderDropdownLink, setOpenedHeaderDropdownLink] = useState(null);

    // Define the navbar classes
    /* eslint-disable-next-line i18next/no-literal-string */
    const navbarClasses = ['navbar', 'navbar-expand-md'];

    // Define the navbar dropdown classes
    /* eslint-disable-next-line i18next/no-literal-string */
    const navbarDropdownClasses = ['collapse', 'navbar-collapse', 'show'];
    if (mobileMenuOpened) {
        /* eslint-disable-next-line i18next/no-literal-string */
        navbarDropdownClasses.push('open');
    }

    /**
     * Show or hide the navbar dropdown
     *
     * @param {boolean} visible - whether the navbar dropdown should be visible or not
     */
    const setNavbarDropdownVisibility = visible => {
        setMobileMenuOpened(visible);

        if (visible) {
            setDataExpanded(true);
        } else {
            // Wait for the dropdown animation to finish (closing the element), and only then set the remaining
            // elements, so we have a smooth transition.
            setTimeout(() => {
                setDataExpanded(false);
            }, navbarDropdownAnimationTime);
        }
    };

    /**
     * Show or hide a HeaderDropdownLink navbar's dropdown based on its ID.
     *
     * Returns a function that takes a boolean to decide whether it should be displayed or not.
     *
     * @param {string} id - ID of the HeaderDropdownLink element to show/hide
     */
    const setOpenedHeaderDropdownLinkById = id => opened => {
        const idToSet = opened ? id : null;
        setOpenedHeaderDropdownLink(idToSet);
    };

    return (
        <StyledNavbar
            className={navbarClasses.join(' ')}
            data-expanded={dataExpanded}
            css={contrast ? contrastTheme : {}}
        >
            <div className="navbar-content-delimiter">
                <div className="mobile-toggle-content-delimiter">
                    <Link className="navbar-brand" to="/">
                        <SwitchLogo />
                    </Link>
                    <HamburgerToggle
                        opened={mobileMenuOpened}
                        setNavbarDropdownVisibility={setNavbarDropdownVisibility}
                    />
                </div>

                <div
                    className={navbarDropdownClasses.join(' ')}
                    id="navbarNavDropdown"
                >
                    <div className="navbar-dropdown-content-wrapper">
                        <ul className="navbar-nav content-items">
                            <HeaderDropdownLink
                                id="productsMenuDropdownLink"
                                name={t('Product')}
                                partialPath="/product"
                                opened={openedHeaderDropdownLink === 'productsMenuDropdownLink'}
                                setOpened={setOpenedHeaderDropdownLinkById('productsMenuDropdownLink')}
                                setNavbarDropdownVisibility={setNavbarDropdownVisibility}
                            >
                                <div className="dropdown-column main">
                                    <Link className="dropdown-item overview">
                                        <img src={OverviewLogo} alt="" className="icon" />
                                        <span className="link-title"><Trans>Overview</Trans></span>
                                        <P2 as="div" className="link-description">
                                            <CTA colored>
                                                <Trans>The payments infrastructure for interoperability.</Trans>
                                            </CTA>
                                        </P2>
                                    </Link>
                                </div>

                                <div className="dropdown-column secondary">
                                    <div className="dropdown-row">
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/product/processing"
                                        >
                                            <img src={ProcessingLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Processing</Trans></span>
                                            <P2 className="link-description">
                                                <Trans>Single Integration Flow.</Trans>
                                            </P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/product/dynamic-routing"
                                        >
                                            <img src={DynamicRoutingLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Dynamic Routing</Trans></span>
                                            <P2 className="link-description"><Trans>Custom routing rules.</Trans></P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/product/vault"
                                        >
                                            <img src={VaultLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Vault</Trans></span>
                                            <P2 className="link-description">
                                                <Trans>Store payment credentials.</Trans>
                                            </P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/product/reconciliation"
                                        >
                                            <img src={ReconciliationLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Reconciliation</Trans></span>
                                            <P2 className="link-description"><Trans>Automate bookkeeping.</Trans></P2>
                                        </Link>
                                    </div>

                                    <div className="dropdown-row">
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/product/risk"
                                        >
                                            <img src={RiskLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Risk</Trans></span>
                                            <P2 className="link-description"><Trans>Detect and block fraud.</Trans></P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/product/analytics"
                                        >
                                            <img src={AnalyticsLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Analytics</Trans></span>
                                            <P2 className="link-description">
                                                <Trans>Interactive data visualization.</Trans>
                                            </P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/product/terminal"
                                        >
                                            <img src={TerminalLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Terminal</Trans></span>
                                            <P2 className="link-description">
                                                <Trans>Unify your payments stack.</Trans>
                                            </P2>
                                        </Link>
                                        <Link className="filler" />
                                    </div>
                                </div>
                            </HeaderDropdownLink>

                            <HeaderDropdownLink
                                id="useCasesMenuDropdownLink"
                                name={t('Use Cases')}
                                partialPath="/use-cases"
                                opened={openedHeaderDropdownLink === 'useCasesMenuDropdownLink'}
                                setOpened={setOpenedHeaderDropdownLinkById('useCasesMenuDropdownLink')}
                                setNavbarDropdownVisibility={setNavbarDropdownVisibility}
                            >
                                <div className="dropdown-column">
                                    <div className="dropdown-row">
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/use-cases/merchants"
                                        >
                                            <img src={MerchantsLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Merchants</Trans></span>
                                            <P2 className="link-description"><Trans>Grow your business.</Trans></P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/use-cases/isvs"
                                        >
                                            <img src={ISVsLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>ISVs</Trans></span>
                                            <P2 className="link-description"><Trans>Meet processing needs.</Trans></P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/use-cases/psps"
                                        >
                                            <img src={PSPsLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>PSPs</Trans></span>
                                            <P2 className="link-description"><Trans>Expand your options.</Trans></P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/use-cases/acquirers"
                                        >
                                            <img src={AcquirersLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Acquirers</Trans></span>
                                            <P2 className="link-description"><Trans>Level up your clients.</Trans></P2>
                                        </Link>
                                    </div>
                                </div>
                            </HeaderDropdownLink>

                            <HeaderLink
                                to="/channels"
                                partialPath="/channels"
                                setNavbarDropdownVisibility={setNavbarDropdownVisibility}
                            >
                                <Trans>Channels</Trans>
                            </HeaderLink>

                            {/*
                                TODO: uncomment this when we have status page (don't forget to confirm the link is
                                correct, as well as removing the existing developer's link
                            */}
                            {/* <HeaderDropdownLink */}
                            {/*    id="developersMenuDropdownLink" */}
                            {/*    name={t('Developers')} */}
                            {/*    partialPath="/developers" */}
                            {/*    opened={openedHeaderDropdownLink === 'developersMenuDropdownLink'} */}
                            {/*    setOpened={setOpenedHeaderDropdownLinkById('developersMenuDropdownLink')} */}
                            {/*    setNavbarDropdownVisibility={setNavbarDropdownVisibility} */}
                            {/* > */}
                            {/*    <div className="dropdown-column"> */}
                            {/*        <div className="dropdown-row"> */}
                            {/*            <Link */}
                            {/*                className="dropdown-item" */}
                            {/*                href="https://switchpayments.com/docs/" */}
                            {/*                target="_blank" */}
                            {/*            > */}
                            {/*                <img src={DocumentationLogo} alt="" className="icon" /> */}
                            {/*                <span className="link-title"><Trans>Documentation</Trans></span> */}
                            {/*                <P2 className="link-description"> */}
                            {/*                    <Trans>Integration and platform components.</Trans> */}
                            {/*                </P2> */}
                            {/*            </Link> */}
                            {/*            <Link */}
                            {/*                className="dropdown-item" */}
                            {/*                href="https://switchpayments.com/api-status/" */}
                            {/*                target="_blank" */}
                            {/*            > */}
                            {/*                <img src={APIStatusLogo} alt="" className="icon" /> */}
                            {/*                <span className="link-title"><Trans>API Status</Trans></span> */}
                            {/*                <P2 className="link-description"><Trans>All systems go.</Trans></P2> */}
                            {/*            </Link> */}
                            {/*        </div> */}
                            {/*    </div> */}
                            {/* </HeaderDropdownLink> */}

                            <HeaderLink
                                to="https://switchpayments.com/docs"
                                setNavbarDropdownVisibility={setNavbarDropdownVisibility}
                            >
                                <Trans>Developers</Trans>
                            </HeaderLink>

                            <HeaderLink
                                to="/learn"
                                partialPath="/learn"
                                setNavbarDropdownVisibility={setNavbarDropdownVisibility}
                            >
                                <Trans>Learn</Trans>
                            </HeaderLink>

                            <HeaderDropdownLink
                                id="aboutMenuDropdownLink"
                                name={t('About')}
                                partialPath="/about"
                                opened={openedHeaderDropdownLink === 'aboutMenuDropdownLink'}
                                setOpened={setOpenedHeaderDropdownLinkById('aboutMenuDropdownLink')}
                                setNavbarDropdownVisibility={setNavbarDropdownVisibility}
                            >
                                <div className="dropdown-column">
                                    <div className="dropdown-row">
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/about/company"
                                        >
                                            <img src={CompanyLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Company</Trans></span>
                                            <P2 className="link-description"><Trans>Our story.</Trans></P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/about/careers"
                                        >
                                            <img src={CareersLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Careers</Trans></span>
                                            <P2 className="link-description"><Trans>The Switch way.</Trans></P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/about/newsroom"
                                        >
                                            <img src={NewsroomLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Newsroom</Trans></span>
                                            <P2 className="link-description"><Trans>Media & Press.</Trans></P2>
                                        </Link>
                                        <Link
                                            className="dropdown-item last"
                                            activeClassName={activeSubNavClassName}
                                            partiallyActive
                                            to="/about/contact"
                                        >
                                            <img src={ContactUsLogo} alt="" className="icon" />
                                            <span className="link-title"><Trans>Contact Us</Trans></span>
                                            <P2 className="link-description"><Trans>Reach out.</Trans></P2>
                                        </Link>
                                    </div>
                                </div>
                            </HeaderDropdownLink>
                        </ul>

                        <ul className="navbar-nav sign-in">
                            <li className="nav-item">
                                <SignIn />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </StyledNavbar>
    );
};

Header.propTypes = {
    contrast: PropTypes.bool,
};

Header.defaultProps = {
    contrast: false,
};


/*
 * Exports
 */
export default Header;
